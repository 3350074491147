import React from 'react'
import { Col, Card, CardBody, CardTitle } from 'reactstrap'
import marc from '../images/mark.jpg'
import james from '../images/james.jpg'
import megan from '../images/megan.jpg'
import anne from '../images/anne.jpg'
import userImage from '../images/joanne.jpg'

export type StartingType = {
    icon: any,
    title: string,
    content: string,
    link?: string
}
export const starting = [
    {
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating1',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating2',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating3',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating4',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating5',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Stating6',
        content: "We've created the marketing campaign of the website. It was a very interesting collaboration."
    },
]

export const why: StartingType[] = [
    {
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Hubungi Layanan',
        content: "Hubungi nomor layanan COVID-19 di 112, "
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Jangan Kemana-mana',
        content: "Tetap tinggal di rumah jaga jarak dengan orang lain termasuk anggota keluarga"
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Siapkan Masker',
        content: "Kenakan masker (tipe masker bedah), dan ganti secara berkala, agar tidak menular ke orang lain "
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Minta Bantuan',
        content: "Minta bantuan teman, anggota keluarga, atau layanan jasa lain untuk menyelesaikan urusan di luar rumah "
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: 'Membatasi Diri',
        content: "Batasi menerima tamu di rumah, hindari kontak langsung dengan tamu untuk mencegah penyebaran virus yang lebih luas "
    },{
        icon: <i className="now-ui-icons ui-1_check"></i>,
        title: '14 Hari',
        content: "Lakukan ini semua selama 14 hari untuk membantu mengurangi penyebaran virus "
    } 
]


export type TeamDisplayPropsType = {
    name: string,
    position: string,
    pic: string,
    content: any,
    socialLink: { [code: string]: string }
    col?: number
}

export const TeamDisplay: React.FC<any> = (props: TeamDisplayPropsType) => { 
    return (
        <Col key={props.name} md={props.col ? props.col : 4}>
            <Card style={{ background: '#82d2f0' }} className="card-profile">
                <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                            alt="..."
                            className="img img-raised"
                            src={props.pic}
                            style={{width: 120}}
                        ></img>
                    </a>
                </div>
                <CardBody>
                    <CardTitle className="sub-header" style={{ color: 'rgb(72, 72, 72)' }} tag="h3">{props.name}</CardTitle>
                    <h6  className="category text-info sub-header" style={{ color: 'rgb(72, 72, 72)' }}>{props.position}</h6>
                    <p className="card-description" style={{color: '#000'}}>
                        {props.content}
                    </p>
                </CardBody>
            </Card>
        </Col>
    )
}




export const teams: { [nama: string]: TeamDisplayPropsType } = {
    marc: {
      name: 'MARC RUMMEL',
      position: 'Managing Director & Shareholder',
      pic: marc,
      content: "Marc has been a Managing Director of Solutions Financial Consultants for the past six years. He has worked in the Financial Sector for the past 12 years and is committed to providing proven wealth management, retirement and protection strategies, complementing the client’s needs and goals.",
      socialLink: {
        fb: "",
        in: '',
        tw: ''
      }
    },
    james: {
      name: 'JAMES TAYLOR',
      position: 'Investment Consultant',
      pic: james,
      content: "James has been working in the financial sector for over 17 years and is an integral part of decision making in the direction of the business. James has extensive experience as a Financial Analyst and is responsible for the research and composition of investment strategies.",
      socialLink: {
        fb: "",
        in: '',
        tw: ''
      }
    },
    megan: {
      name: 'MEGAN STEVENS',
      position: 'Financial Controller',
      pic: megan,
      content: "Megan’s responsibilities as Financial Controller includes the preparation of tax, creating and maintaining the Company’s accounting system, processing & recording transactions and payroll.",
      socialLink: {
        fb: "",
        in: '',
        tw: ''
      }
    },
    anne: {
      name: 'ANNE NOLAN',
      position: 'Client Services Manager',
      pic: anne,
      content: "Anne joined the team in January 2015 bringing 18 years experience of customer service and administrative experience. She maintains exceptional service with our clientele and enjoys the close relationships between the team at Solutions",
      socialLink: {
        fb: "",
        in: '',
        tw: ''
      }
    },
    josephine: {
      name: 'JOSEPHINE RUMMEL',
      position: 'Administration Officer',
      pic: userImage,
      content: "Josephine is an intergral part of the Solutions Financial Consultants team. She supports the team by delivering excellent administrative service to our clients and her attention to detail is second to none.",
      socialLink: {
        fb: "",
        in: '',
        tw: ''
      }
    },
  }

  